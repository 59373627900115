import React, { useState, useEffect } from "react"
import moment from "moment"

import Layout from "components/layout"
import { useQuery, useLazyQuery } from "@apollo/client"
import { parseCookies } from 'nookies'
import { Modal } from "components/anti/modal/modal"
import { Accordion } from "components/anti/accordion/accordion"
import { Button } from "components/anti/buttons/buttons"
import { NumFormat } from "components/anti/num-format/num-format"
import Seo from "components/seo"

import { getBpjsTransactionsQuery, getBpjsPendingPaymentQuery } from "components/utils/streamworx"


const Covid19TestRiwayat = () => {

    const authTokenNew = parseCookies().authToken ? `Bearer ${parseCookies().authToken}` : null
    const streamworx_config = { context: { clientName: 'streamworx', headers: { authorization: authTokenNew, }, } }

    const { data: getBpjsTransactions } = useQuery(getBpjsTransactionsQuery, streamworx_config);
    const [getBpjsPendingPaymentFunc, { data: getBpjsPendingPayment }] = useLazyQuery(getBpjsPendingPaymentQuery, streamworx_config);

    const [selectedBPJS, setSelectedBPJS] = useState()
    const [showModal, setShowModal] = useState("")
    const [expanded, setExpanded] = useState("");



    useEffect(() => {
        (async () => {
            const BPJSTransactions = await getBpjsTransactions
            if (BPJSTransactions?.getBpjsTransactions) {
                setSelectedBPJS(BPJSTransactions?.getBpjsTransactions[0])
                const referenceId = BPJSTransactions?.getBpjsTransactions[0]?.referenceId
                getBpjsPendingPaymentFunc({
                    variables: { referenceId }
                })
            }
        })()

    }, [getBpjsTransactions])

    const changeSelected = (bpjs) => {
        setSelectedBPJS(bpjs)
        const referenceId = bpjs.referenceId
        getBpjsPendingPaymentFunc({
            variables: { referenceId }
        })
    }

    const handleAccordion = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : "undefined");
    };



    return (
        <Layout>
            <Seo title="Riwayat BPJS" />
            <section className="py-main sc-covid19Test-riwayat">
                <div className="container">
                    <Button
                        variant="link"
                        className="btn-back-help ai-arrow-from-left mb-3"
                        link={"/bpjskesehatan"}
                    >
                        Kembali
                    </Button>
                    <h2 className="mb-4">Riwayat Pembayaran BPJS Kesehatan</h2>
                    {getBpjsTransactions?.getBpjsTransactions?.length <= 0 && (
                        <div className='w-100'>
                            <p className="text-center text-muted">Anda belum pernah melakukan transaksi</p>
                        </div>
                    )}
                    <div className="row row-4">
                        <div className="col-md-4">
                            <ul className="riwayat-list h-1000px overflow-auto">
                                {getBpjsTransactions && getBpjsTransactions?.getBpjsTransactions?.map((bpjs, i) => {
                                    const status = bpjs.paymentStatus
                                    let classStatus = ""
                                    if (status === "Paid") {
                                        classStatus = "SUCCESS"
                                    } else if (status === "Failed Payment") {
                                        classStatus = "FAILED"
                                    } else if (status === "Expired Payment") {
                                        classStatus = "FAILED"
                                    } else if (status === "Payment Cancelled") {
                                        classStatus = "CANCELLED"
                                    } else {
                                        classStatus = "PENDING"
                                    }
                                    return (
                                        <li className={`btn list-content p-2 ${bpjs?.referenceId === selectedBPJS?.referenceId && "active"}`} onClick={() => changeSelected(bpjs)} key={i}>
                                            <div className="list-card">
                                                <p className="mb-1">{moment(bpjs?.transactionDate).format("DD MMM YYYY, HH:mm")}</p>
                                                <h5 className="mb-2">{bpjs?.name}</h5>
                                                <p><span className={`border-status p-1 text-uppercase font-weight-bold ${classStatus}`}>{bpjs?.paymentStatus}</span></p>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        {selectedBPJS && (
                            <div className="col-md-8">
                                <p className="caption bg-light py-2 px-3">Detail Pembelian</p>
                                <div className="p-3">
                                    <div className="status mb-4">
                                        <p className="caption mb-0">Status</p>
                                        <p className="mb-0">{selectedBPJS?.paymentStatus}</p>
                                        {selectedBPJS?.paymentStatus === "Pending Payment" && (
                                            <Button variant="link" onClick={() => setShowModal("bayarTagihanModal")}>
                                                Lihat Instruksi Pembayaran
                                            </Button>
                                        )}
                                    </div>
                                    <div className="invoice mb-4">
                                        <p className="caption mb-0">Nomor Invoice</p>
                                        <p className="mb-0">{selectedBPJS?.invoiceNo}</p>
                                    </div>
                                    <div className="va-kepala-keluarga mb-4">
                                        <p className="caption mb-0">Nomor VA Kepala Keluarga</p>
                                        <p className="mb-0">{selectedBPJS?.bpjsDetail?.noVAKepalaKeluarga}</p>
                                    </div>
                                    <div className="va mb-4">
                                        <p className="caption mb-0">Nomor VA</p>
                                        <p className="mb-0">{selectedBPJS?.bpjsDetail?.noVAKeluarga}</p>
                                    </div>
                                    <div className="nama mb-4">
                                        <p className="caption mb-0">Nama</p>
                                        <p className="mb-0">{selectedBPJS?.bpjsDetail?.namaPeserta}</p>
                                    </div>
                                    {/* <div className="cabang mb-4">
                                        <p className="caption mb-0">Nama Cabang</p>
                                        <p className="mb-0">Jakarta Pusat</p>
                                    </div> */}
                                    <div className="jumlah-anggota mb-4">
                                        <p className="caption mb-0">Jumlah Anggota Keluarga</p>
                                        <p className="mb-0">{selectedBPJS?.bpjsDetail?.jumlahAnggota}</p>
                                    </div>
                                    <div className="jumlah-periode mb-4">
                                        <p className="caption mb-0">Jumlah Periode Pembayaran</p>
                                        <p className="mb-0">{selectedBPJS?.bpjsDetail?.jumlahPeriode}</p>
                                    </div>
                                </div>
                                <p className="caption bg-light py-2 px-3">Rincian Biaya</p>
                                <div className="p-3">
                                    <div className="biaya-iuran mb-4">
                                        <p className="caption mb-0">Biaya Iuran</p>
                                        <p className="mb-0">Rp{NumFormat(selectedBPJS?.bpjsDetail?.biayaIuran)}</p>
                                    </div>
                                    <div className="biaya-admin mb-4">
                                        <p className="caption mb-0">Biaya Admin</p>
                                        <p className="mb-0">Rp{NumFormat(selectedBPJS?.bpjsDetail?.biayaAdmin)}</p>
                                    </div>
                                    <div className="biaya-admin mb-4">
                                        <p className="caption mb-0">Biaya Servis</p>
                                        <p className="mb-0">Rp{NumFormat(selectedBPJS?.serviceFee)}</p>
                                    </div>
                                    <div className="nominal mb-4">
                                        <p className="caption mb-0">Nominal yang perlu Dibayar</p>
                                        <h5 className="mb-0">Rp{NumFormat(selectedBPJS?.totalPrice)}</h5>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
            <Modal
                id="bayarTagihanModal"
                // title="Pembayaran BPJS Kesehatan"
                // img={bank_bri}
                isShowing={showModal}
                // hide={closeModal}
                className="modal-md bayar-tagihan-modal sc-bpjs-kategori"
            >
                <div className="bayar-tagihan-modal-content animated fadeInDown delayp5">
                    <div className="modal-container mb-4">
                        <img
                            src={getBpjsPendingPayment?.getBpjsPendingPayment?.paymentChannelIcon}
                            className={`img-fluid`}
                            alt={"logo"}
                        />
                    </div>
                    <div className="modal-container bg-light pt-4">
                        <div className="mb-4">
                            <p className="caption mb-0">Nomor Invoice</p>
                            <p className="mb-4 font-weight-bold">{getBpjsPendingPayment?.getBpjsPendingPayment?.invoiceNo}</p>
                        </div>
                        <div className="mb-4">
                            <p className="caption mb-0">No. Virtual Account Bank</p>
                            <p className="mb-4 font-weight-bold">{getBpjsPendingPayment?.getBpjsPendingPayment?.virtualAccountNumber}</p>
                        </div>
                        <div className="">
                            <p className="caption mb-0">Total Pembayaran</p>
                            <h3 className="pb-4 mb-0">Rp{NumFormat(getBpjsPendingPayment?.getBpjsPendingPayment?.totalAmount)}</h3>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between modal-container countdown py-2 mb-2">
                        <p className="mb-0">Batas Waktu Pembayaran</p>
                        <p className="mb-0">{moment(getBpjsPendingPayment?.getBpjsPendingPayment?.expiresAt).format("DD MMM YYYY, HH:mm")}</p>
                    </div>
                    <div className="modal-container py-4 h-md-350px overflow-auto gradient-list ">
                        {getBpjsPendingPayment?.getBpjsPendingPayment?.howToPay?.map((how, i) => {
                            return (
                                <Accordion
                                    expandIconPlus
                                    title={how.paymentType}
                                    className={`accordion-home-update-category`}
                                    key={i}
                                    defaultExpanded={getBpjsPendingPayment?.getBpjsPendingPayment?.howToPay[0].paymentType}
                                    expandedKey={how.paymentType}
                                    currentExpanded={expanded}
                                    onChange={handleAccordion(how.paymentType)}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: how.instruction }} />
                                </Accordion>
                            )
                        })}
                    </div>
                    <div className="modal-container">
                        <Button variant="primary" className="btn-block" onClick={() => setShowModal("")}>
                            Tutup
                        </Button>
                    </div>
                </div>
            </Modal>
        </Layout>
    )
}


export default Covid19TestRiwayat